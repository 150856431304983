import { useLocale } from '@dfds-frontend/navigations'
import { Accordion, AccordionSmall } from '@dfds-ui/react-components'
import { FlexBox } from '@dfds-ui/react-components/flexbox'
import { theme } from '@dfds-ui/theme'
import { Text } from '@dfds-ui/typography'
import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { hasOwnLocalizedContent } from '../../utils'
import { Markdown } from '../Markdown'
import FaqSeoSnippet from './FaqSeoSnippet'

const Faq = ({ items, heading, urlSlug, defaultExpanded }) => {
  const locale = useLocale()
  const [filteredItems, setFilteredItems] = useState([])
  const [parentOpen, setParentOpen] = useState(true)
  const [childrenOpen, setChildrenOpen] = useState(
    filteredItems.map(() => defaultExpanded),
  )
  const { t } = useTranslation()

  // Show only faq items with translated content
  useEffect(() => {
    const fetchData = async () => {
      const result = []
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        const showFAQ = await hasOwnLocalizedContent(
          'seoFaq',
          item.sys.id,
          'fields.question',
          locale,
        )
        if (showFAQ) result.push(item)
      }
      setFilteredItems(result)
    }

    fetchData()
  }, [items, locale])

  const onToggle = (index) => {
    if (defaultExpanded) {
      // Toggle the accordion clicked, leave the rest
      setChildrenOpen((prevOpen) =>
        filteredItems.map((item, i) =>
          i === index ? !prevOpen[i] : prevOpen[i],
        ),
      )
    } else {
      // Toggle the accordion clicked, close the rest
      setChildrenOpen((prevOpen) =>
        filteredItems.map((item, i) => i === index && !prevOpen[i]),
      )
    }
  }

  const faqQuestionsSection = (translation) => {
    try {
      const text =
        translation?.split('<1>')[1] &&
        translation.split('<1>')[1].split('</1>')[0]

      const faqAnchor =
        translation?.split('<0>')[1] &&
        translation.split('<0>')[1].split('</0>')[0]

      return (
        <FlexBox>
          <Text as={'div'} styledAs={'body'}>
            {text}
          </Text>
          <a
            css={css`
              color: ${theme.colors.secondary.main};
              text-decoration: none;
              margin-left: ${theme.spacing.xs};
              &:hover {
                color: ${theme.colors.secondary.dark};
              }
            `}
            href={`${origin}/${locale}/${urlSlug}`}
            target="_blank"
            rel="noreferrer"
          >
            {faqAnchor}
          </a>
          .
        </FlexBox>
      )
    } catch (err) {
      return
    }
  }

  if (!filteredItems.length) return <></>
  return (
    <>
      <FaqSeoSnippet listItem={filteredItems} />
      <Accordion
        onToggle={() => setParentOpen((s) => !s)}
        isOpen={parentOpen}
        heading={heading ?? 'FAQ'}
        css={css`
          margin-bottom: 20px;
        `}
      >
        {filteredItems.map((item, index) => {
          return (
            <AccordionSmall
              key={item.sys.id}
              onToggle={() => onToggle(index)}
              isOpen={childrenOpen[index]}
              heading={item.question}
            >
              <Markdown source={item.answer.text} noBottomMargin />
            </AccordionSmall>
          )
        })}
        {urlSlug && faqQuestionsSection(t('FAQ-QUESTIONS-SECTION'))}
      </Accordion>
    </>
  )
}

export const faqFragment = graphql`
  fragment FaqFragment on contentful_FaqSection {
    heading
    defaultExpanded
    itemsCollection(limit: 10) {
      items {
        ...FaqSeoFragment
      }
    }
  }

  fragment FaqSeoFragment on contentful_SeoFaq {
    question
    sys {
      id
    }
    answer {
      text
    }
  }
`

export default Faq
