import { Script } from 'gatsby'

const FaqSeoSnippet = ({ listItem }) => {
  const removeBrackets = (text) => {
    const squareBrackets = /\[(.*?)\]/
    const roundBrackets = / *\([^)]*\) */g

    try {
      if (text.includes('[') && text.includes('(')) {
        const newString = text.replace(roundBrackets, '')
        const url = text.match(squareBrackets)[1]
        return newString.replace(squareBrackets, '') + url
      }
      return text
    } catch (err) {
      return
    }
  }

  const mainEntity =
    listItem &&
    listItem.map((item) => {
      const faqObj = {
        '@type': 'Question',
        name: item.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: removeBrackets(item.answer.text),
        },
      }
      return faqObj
    })

  const seoSnippet = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity,
  }

  return (
    <Script type="application/ld+json" off-main-thread>{`${JSON.stringify(
      seoSnippet,
    )}`}</Script>
  )
}

export default FaqSeoSnippet
